import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import SocialShare from '../components/SocialShare'
import NewsletterForm from '../components/NewsletterForm'
import RelatedPosts from '../components/RelatedPosts'

class BlogPostTemplate extends React.Component {
  render () {
    const post = this.props.data.mdx
    const site = this.props.data.site
    return (
      <Layout location={this.props.location}>
        <SEO
          title={post.frontmatter.title}
          pathname={this.props.location.pathname}
        />
        <div className='post container'>
          <p className='back-to-list'>
            <Link to='/'>&larr; Home</Link>
          </p>

          <h1>{post.frontmatter.title}</h1>

          <MDXRenderer>{post.body}</MDXRenderer>
          <NewsletterForm />
          <SocialShare
            socialConfig={{
              config: {
                twitterData: '@aspittel',
                url: `${site.siteMetadata.siteUrl}${this.props.location.pathname}`,
                title: post.frontmatter.title
              }
            }}
          />
          <RelatedPosts posts={this.props.data.relatedPosts} />
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $relatedPosts: [String!]) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        relatedPosts
      }
    }
    relatedPosts: allMdx(filter: { fields: { slug: { in: $relatedPosts } } }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
          }
        }
      }
    }
  }
`

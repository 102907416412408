import React from "react"
import { FaTwitter, FaLinkedin, FaReddit, FaFacebook } from "react-icons/fa"

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share"

import "./SocialShare.css"

const SocialShare = ({ socialConfig, tags }) => (
  <div>
    <h2 style={{ textAlign: "center" }}>Share this post with a friend!</h2>

    <div className="post-social">
      <FacebookShareButton
        url={socialConfig.config.url}
        className="button is-outlined is-rounded facebook"
      >
        <span className="icon">
          <FaFacebook />
        </span>
        <span className="text">Facebook</span>
      </FacebookShareButton>
      <TwitterShareButton
        url={socialConfig.config.url}
        className="button is-outlined is-rounded twitter"
        title={socialConfig.config.title}
        via="aspittel"
        hashtags={tags}
      >
        <span className="icon">
          <FaTwitter />
        </span>
        <span className="text">Twitter</span>
      </TwitterShareButton>
      <LinkedinShareButton
        url={socialConfig.config.url}
        className="button is-outlined is-rounded linkedin"
        title={socialConfig.config.title}
      >
        <span className="icon">
          <FaLinkedin />
        </span>
        <span className="text">LinkedIn</span>
      </LinkedinShareButton>
      <RedditShareButton
        url={socialConfig.config.url}
        className="button is-outlined is-rounded reddit"
        title={socialConfig.config.title}
      >
        <span className="icon">
          <FaReddit />
        </span>
        <span className="text">Reddit</span>
      </RedditShareButton>
    </div>
  </div>
)

export default SocialShare

import React from 'react'
import { Link } from 'gatsby'

const RelatedPosts = ({ posts }) => {
  return (
    <section className='featured-posts'>
      {posts.edges.map(post => (
        <div key={post.node.fields.slug} className='featured-post'>
          <Link to={post.node.fields.slug}>
            <h2>{post.node.frontmatter.title}</h2>
          </Link>
          <span>{post.node.frontmatter.date}</span>
          <Link to={post.node.fields.slug} className='read-more'>
            Read More
          </Link>
        </div>
      ))}
    </section>
  )
}
export default RelatedPosts
